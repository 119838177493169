import * as React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Experience from "../components/experience"
import Profile from "../components/profile"
import Projects from "../components/projects"
import Education from "../components/education"
import Certifications from "../components/certifications";
import Layout from "../components/layout";

const IndexPage = () => {

  const data = useStaticQuery(query);
  const profileData = data.allContentfulProfile.nodes[0];
  const skillsData = data.allContentfulSkills.nodes;
  const socialLinks = data.allContentfulSocialLinks.nodes;
  const educationData = data.allContentfulEducation.nodes;
  const projectsData = data.allContentfulProject.nodes;
  const experienceData = data.allContentfulWorkExperience.nodes;
  const certificationsData = data.allContentfulCertifications.nodes
  const gravatar = data.gravatar;
  const locale = "fr-CA";

  return (
    <Layout profileData={profileData} gravatar={gravatar}>
      <div className="bg-dark-gray w-full min-h-screen">
        <div className="w-full max-w-6xl mx-auto mt-2 flex justify-between md:flex-no-wrap flex-wrap md:pl-8 md:pr-8">

          <div class="md:w-1/3 w-full">
            <Profile profileData={profileData} socialLinks={socialLinks} locale={locale} skills={skillsData}></Profile>
          </div>
          <div class="md:w-2/3 w-full">
            <Experience experienceData={experienceData} locale={locale}></Experience>
            <Certifications certificationsData={certificationsData}></Certifications>
            <Projects projectsData={projectsData} locale={locale}></Projects>
            <Education educationData={educationData} locale={locale}></Education>
          </div>

        </div >
      </div >
    </Layout>
  )
}

const query = graphql`
        {
            allContentfulProfile(limit: 1, filter: {node_locale: {eq: "fr-CA"}}) {
                nodes {
                    headerTitle
                    node_locale
                    name
                    siteTitle
                    skills {
                        raw
                    }
                   aboutMe {
                        raw
                    }
                }
            }
            allContentfulSocialLinks(filter: {node_locale: {eq: "en-US"}}) {
              nodes {
                name
                url
              }
            }
            allContentfulEducation(sort: {fields: endDate, order: DESC} filter: {node_locale: {eq: "fr-CA"}}) {
              nodes {
                  degree
                  url
                  endDate(formatString: "MMMM YYYY", locale: "fr")
                  schoolName
                  startDate(formatString: "MMMM YYYY", locale: "fr")
              }
            }
            allContentfulProject(sort: {order: DESC, fields: createdAt} filter: {node_locale: {eq: "fr-CA"}}) {
              nodes {
                name
                devicon
                projectDescription {
                  raw
                }
                technology
                url
                createdAt
              }
            }
            allContentfulWorkExperience(sort: {order: DESC fields: endDate} filter: {node_locale: {eq: "fr-CA"}}) {
              nodes {
                company
                url
                jobTitle
                startDate(formatString: "MMMM YYYY", locale: "fr")
                endDate(formatString: "MMMM YYYY", locale: "fr")
                jobDescription{
                    raw
                }
                node_locale
                visible
              }
            }
            
            allContentfulSkills(sort: {fields: createdAt, order: ASC} filter: {node_locale: {eq: "en-US"}}) {
              nodes {
                devicon
                name
                url
              }
            }

            gravatar {
              url
            }
            allContentfulCertifications(filter: {node_locale: {eq: "en-US"}}) {
                nodes {                  
                  issuer
                  issueDate(formatString: "MMMM YYYY")
                  issuerWebsite
                  title
                  description {
                    raw
                  }                  
                }
            }                

        }
    `;


export default IndexPage
